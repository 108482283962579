import type { OddsFormat } from "../store/store";

const convertDecimalOddsToAmerican = (odds: number) => {
  if (odds >= 2) {
    return (odds - 1) * 100;
  } else {
    return -100 / (odds - 1);
  }
};

export const convertAmericanOddsToDecimal = (odds: number) => {
  if (odds > 0) {
    return (odds + 100) / 100;
  } else {
    return (odds + 100) / -odds;
  }
};

export const formatOdds = (
  odds: string | number,
  oddsFormat: OddsFormat
): number => {
  const oddsAsNumber = typeof odds === "number" ? odds : parseFloat(odds);

  if (isNaN(oddsAsNumber)) {
    return oddsAsNumber;
  }

  if (oddsAsNumber === 0) {
    return 0;
  }

  switch (oddsFormat) {
    case "decimal":
      return parseFloat(oddsAsNumber.toFixed(3));
    case "american":
      return Math.round(convertDecimalOddsToAmerican(oddsAsNumber));
  }
};

export const formatOddsHandleUndefined = (
  odds: string | number | undefined,
  oddsFormat: OddsFormat
): number | undefined => {
  if (typeof odds === "undefined") {
    return undefined;
  }

  return formatOdds(odds, oddsFormat);
};
