import type { Alert } from "../types/redis/alerts";

// NB: Very important that this is the same in Haddock
const DEDUPE_SIMILAR_ALERTS_TIME_MS = 5 * 60 * 1000; // 5 minutes

interface Accumulator {
  [eventIdPeriodLineTypeCompoundKey: string]: Alert[];
}

/**
 *
 * NB: Very important! This function is used in Haddock.
 * If you change the logic here, you need to change it in both places.
 */
export const mergeSimilarAlerts = (
  alerts: Alert[],
  alertingCriteriaIdsWithPushNotificationsEnabled: string[],
  /**
   * Some alerts are identical but are for different alerting criteria.
   * This flag is useful for when we don't want to show the same alert for different alerting criteria e.g.
   * when the user is in the 'all' tab of an alerts table or in Instant Alerts module.
   */
  dedupeIdenticalAlertsFromDifferentAlertingCriteria?: boolean
) => {
  const alertsByCompoundKeys: Accumulator = {};

  /**
   * Sort alerts by oldest first, so that we add old alerts to compound keys first
   * and then add newer alerts to compound keys if they are over 5 minutes old.
   * If it was the other sort order then new alerts would be added to compound keys first
   * and so old alerts would disappear from the UI if there was a new alert for the same compound key.
   */
  const sortedAlerts = alerts.sort(
    (a, b) => parseInt(a.timestamp) - parseInt(b.timestamp)
  );

  for (const alert of sortedAlerts) {
    if (alert.type === "openingLine") {
      alertsByCompoundKeys[alert.id] = [alert];
      continue;
    }
    /**
     * We use a compound key to group alerts by the same nickname, type, event, period, and line type
     * so that we can compare the timestamps of the alerts and only add the alert
     * if over 5 minutes has elapsed since the last alert for the nickname, type, event, period, and line type
     *
     * Nickname is not unique which is not ideal, in future it would be better if we saved the
     * alertingCriteriaId on an alert and used that as the unique identifier. Here we could have a bug where
     * user has the same nickname for different alerting criteria and so only one of the alerts will be shown.
     * That's quite unlikely though cos would be weird to name your alerting criteria the same thing...
     *
     */
    const isAlertFromAlertingCriteriaWithPushNotificationsEnabled =
      alert.alertingCriteriaId &&
      alertingCriteriaIdsWithPushNotificationsEnabled.includes(
        alert.alertingCriteriaId
      );
    /**
     * We realized there would be an issue when people had multiple alerting configurations
     * enabled and then a subset of those with push notifications enabled. Eg.
     * AC #1 is enabled and has push notifications disabled
     * AC #2 is enabled and has push notifications enabled
     * AC #3 is enabled and has push notifications disabled
     *
     * In the case above let's say all AC are for soccer. Let's say the user gets a dropNotification (alert)
     * for AC #1 which triggers when there is a 5% drop in odds. This drop notification is shown in
     * the frontend alert feed but doesn't send a push notification because push notifications are disabled
     * for AC #1. Then 2 minutes later there is another drop for the same event, line type etc. and this time it
     * triggers AC #2 which is set to 10% drops. AC #2 has push notifications enabled so it should send a push notification.
     * However if we didn't add the "push/no-push" to the compound key then the push notification for the 10% drop would
     * not be sent because the alert would be merged into the 5% drop notification.
     *
     * It's our opinion that it's better to send push notifications for alerts that would've been merged under the previous
     * logic (without having push/no-push in the compound key). This is because the user has explicitly enabled push notifications
     * for that AC and we want to honour that setting.
     *
     */
    const compoundKey = dedupeIdenticalAlertsFromDifferentAlertingCriteria
      ? `${
          isAlertFromAlertingCriteriaWithPushNotificationsEnabled
            ? "push"
            : "no-push"
        }-${alert.type}-${alert.eventId}-${alert.periodNumber}-${
          alert.lineType
        }`
      : `${alert.nickname}-${alert.type}-${alert.eventId}-${alert.periodNumber}-${alert.lineType}`;

    if (!alertsByCompoundKeys[compoundKey]) {
      alertsByCompoundKeys[compoundKey] = [alert];
      continue;
    }

    const mostRecentAlertForCompoundKey =
      alertsByCompoundKeys[compoundKey]?.at(-1)?.timestamp;

    if (!mostRecentAlertForCompoundKey) {
      throw new Error(
        "This should never happen, bug with merge similar alert logic."
      );
    }

    const alertInAccumulatorTimestamp = parseInt(mostRecentAlertForCompoundKey);
    const currentValueTimestamp = parseInt(alert.timestamp);

    const createdAtDiffInMs =
      currentValueTimestamp - alertInAccumulatorTimestamp;

    /**
     * If over 5 minutes has elapsed since there was an alert for the
     * same alert type, event, period, and line type then add the alert
     */
    if (createdAtDiffInMs > DEDUPE_SIMILAR_ALERTS_TIME_MS) {
      alertsByCompoundKeys[compoundKey]?.push(alert);
    }
  }

  return (
    Object.values(alertsByCompoundKeys)
      .flat()
      /**
       * Sort alerts by newest first, this is for the UI
       */
      .sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp))
  );
};
