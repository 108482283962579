/**
 * Define a cache object outside the function scope to persist across function calls.
 *
 * We implement this cache to avoid recalculating the same noVigPrices for the same input because
 * computing Power and Shin method noVigPrices can be computationally expensive due to iterations required.
 */
const calculateNoVigPricesCache = new Map<
  string,
  ReturnType<typeof calculateNoVigPrices>
>();

/**
 *
 * @param decimalPricesForAllSides should be an object with the following structure:
 * {[key: string]: number}
 * where the key is the side and the value is the decimal odds for that side. The
 * function should return an object with the following structure:
 * {
 *  powerMethodNoVigPrice: {[key: string]: number},
 *  additiveMethodNoVigPrice: {[key: string]: number},
 *  multiplicativeMethodNoVigPrice: {[key: string]: number}
 * }
 * where the key is the side and the value is the no vig price for that side.
 *
 * Example:
 * const decimalPricesForAllSides = {
 *  home: 1.8,
 * draw: 3.5,
 * away: 4.5
 * }
 * const noVigPrices = calculateNoVigPrices(decimalPricesForAllSides);
 * console.log(noVigPrices);
 * // {
 * //   powerMethodNoVigPrice: { home: 1.8, draw: 3.5, away: 4.5 },
 * //   additiveMethodNoVigPrice: { home: 1.8, draw: 3.5, away: 4.5 },
 * //   multiplicativeMethodNoVigPrice: { home: 1.8, draw: 3.5, away: 4.5 }
 * // }
 *
 */
export const calculateNoVigPrices = <
  T extends Record<string, number | undefined | null>
>(
  decimalPricesForAllSides: T
): {
  power: T;
  additive: T;
  multiplicative: T;
  shin: T;
} => {
  // If all sides are undefined, return the same object
  if (
    Object.values(decimalPricesForAllSides).every(
      (decimalPrice) => decimalPrice === undefined
    )
  ) {
    return {
      power: decimalPricesForAllSides,
      additive: decimalPricesForAllSides,
      multiplicative: decimalPricesForAllSides,
      shin: decimalPricesForAllSides,
    };
  }

  // Generate a unique cache key based on the input
  const cacheKey = JSON.stringify(decimalPricesForAllSides);

  // Check if the result is already cached
  if (calculateNoVigPricesCache.has(cacheKey)) {
    return calculateNoVigPricesCache.get(cacheKey) as ReturnType<
      typeof calculateNoVigPrices<T>
    >;
  }

  // Convert decimal prices to probabilities and map them with their keys
  const probabilitiesWithKeys = Object.entries(decimalPricesForAllSides)
    .map(([key, decimalPrice]) => ({
      key,
      probability: decimalPrice ? 1 / decimalPrice : 0,
    }))
    .filter((entry) => entry.probability !== 0);

  // Extract just the probabilities for calculation
  const probabilities = probabilitiesWithKeys.map((entry) => entry.probability);

  const powerMethodNoVigProbabilities = _adjustPower(probabilities);
  const shinMethodNoVigProbabilities = _adjustShin(probabilities);
  const additiveMethodNoVigProbabilities = _adjustAdditive(probabilities);
  const multiplicativeMethodNoVigProbabilities =
    _adjustMultiplicative(probabilities);

  // Initialize the result objects
  const powerMethodNoVigPrices = {} as T;
  const shinMethodNoVigPrices = {} as T;
  const additiveMethodNoVigPrices = {} as T;
  const multiplicativeMethodNoVigPrices = {} as T;

  // Assign calculated probabilities back to their respective keys
  probabilitiesWithKeys.forEach((entry, index) => {
    const key = entry.key as keyof T;
    powerMethodNoVigPrices[key] = _convertProbabilityToDecimalOdds(
      powerMethodNoVigProbabilities[index] ?? 0
    ) as T[keyof T];
    shinMethodNoVigPrices[key] = _convertProbabilityToDecimalOdds(
      shinMethodNoVigProbabilities[index] ?? 0
    ) as T[keyof T];
    additiveMethodNoVigPrices[key] = _convertProbabilityToDecimalOdds(
      additiveMethodNoVigProbabilities[index] ?? 0
    ) as T[keyof T];
    multiplicativeMethodNoVigPrices[key] = _convertProbabilityToDecimalOdds(
      multiplicativeMethodNoVigProbabilities[index] ?? 0
    ) as T[keyof T];
  });

  // After calculating the noVigPrices, store them in the cache before returning
  const result = {
    power: powerMethodNoVigPrices,
    additive: additiveMethodNoVigPrices,
    multiplicative: multiplicativeMethodNoVigPrices,
    shin: shinMethodNoVigPrices,
  };

  calculateNoVigPricesCache.set(cacheKey, result);

  return result;
};

const _convertProbabilityToDecimalOdds = (probability: number) => {
  return 1 / probability;
};

/**
 * Power method for devigging probabilities
 */
const _adjustPower = (
  probabilities: number[],
  tolerance = 1e-4,
  maxIterations = 100
) => {
  let k = 1;
  let adjustedProbabilities = probabilities.map((prob) => Math.pow(prob, k));

  // Perform Newton-Raphson iterations on the exponent k until the overround is within
  // the tolerance or maximum iterations are reached.
  for (let i = 0; i < maxIterations; i++) {
    const overround =
      adjustedProbabilities.reduce((sum, prob) => sum + prob, 0) - 1;
    const denominator = probabilities.reduce(
      (sum, prob) => sum + Math.log(prob) * Math.pow(prob, k),
      0
    );
    k -= overround / denominator;
    adjustedProbabilities = probabilities.map((prob) => Math.pow(prob, k));
    if (Math.abs(overround) < tolerance) {
      break;
    }
  }

  return adjustedProbabilities;
};

/**
 * Shin method for devigging probabilities
 */
const _adjustShin = (
  probabilities: number[],
  tolerance = 1e-4,
  maxIterations = 100
) => {
  const overround = probabilities.reduce((sum, prob) => sum + prob, 0);
  const n = probabilities.length;
  const a = probabilities.map((prob) => prob ** 2 / overround);
  if (n === 2) {
    // The special case of two possible outcomes has an analytical solution.
    const firstProbability = probabilities[0];
    const secondProbability = probabilities[1];
    if (!firstProbability || !secondProbability) {
      throw new Error("Invalid probabilities for calculation.");
    }

    const diff = firstProbability - secondProbability;
    const diffSquared = Math.pow(diff, 2);
    const z =
      ((overround - 1) * (diffSquared - overround)) /
      (overround * (diffSquared - 1));
    const adjustedProbabilities = a.map(
      (prob) => (Math.sqrt(z ** 2 + 4 * (1 - z) * prob) - z) / (2 * (1 - z))
    );
    return adjustedProbabilities;
  } else {
    // Perform Newton-Raphson iterations on the proportion of 'knowledgeable punters' z
    // until the condition is within the tolerance or maximum iterations are reached.
    // Here we use the root-finding condition corresponding to equation 3 in:
    // Štrumbelj, E. (2014). On determining probability forecasts from betting odds.
    // International Journal of Forecasting, 30(4), 934–943.
    let z = 0;
    const b = 1 / (n - 2);
    for (let i = 0; i < maxIterations; i++) {
      const c = a.map((prob) => Math.sqrt(z ** 2 + 4 * (1 - z) * prob));
      const cond = z - b * (c.reduce((sum, value) => sum + value, 0) - 2);
      const denominator =
        1 -
        b *
          a.reduce((sum, prob, index) => {
            const cIndex = c[index];
            if (!cIndex) {
              throw new Error("Invalid cIndex for calculation.");
            }

            return sum + (z - 2 * prob) / cIndex;
          }, 0);
      z -= cond / denominator;
      if (Math.abs(cond) < tolerance) {
        break;
      }
    }
    const adjustedProbabilities = a.map(
      (prob) => (Math.sqrt(z ** 2 + 4 * (1 - z) * prob) - z) / (2 * (1 - z))
    );
    return adjustedProbabilities;
  }
};

/**
 * Additive method for devigging probabilities
 */
const _adjustAdditive = (probabilities: number[]) => {
  const n = probabilities.length;
  const overround = probabilities.reduce((sum, prob) => sum + prob, 0) - 1;

  const adjustedProbabilities = probabilities.map(
    (prob) => prob - overround / n
  );
  return adjustedProbabilities;
};

/**
 * Multiplicative method for devigging probabilities
 */
const _adjustMultiplicative = (probabilities: number[]) => {
  const booksum = probabilities.reduce((sum, prob) => sum + prob, 0);

  const adjustedProbabilities = probabilities.map((prob) => prob / booksum);
  return adjustedProbabilities;
};
