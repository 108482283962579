import {
  KBarResults,
  KBarSearch,
  useKBar,
  useMatches,
  useRegisterActions,
} from "kbar";
import { useEffect, useMemo, useState } from "react";
import { useEventsStore } from "../../store/store";
import { api } from "../../utils/api";
import useDebounce from "../../utils/useDebounce";
import { format } from "date-fns";
import { cn } from "../../lib/utils";
import posthog from "posthog-js";

const actions = [
  {
    id: "blog",
    name: "Blog",
    shortcut: ["b"],
    keywords: "writing words",
    perform: () => (window.location.pathname = "blog"),
  },
  {
    id: "contact",
    name: "Contact",
    shortcut: ["c"],
    keywords: "email",
    perform: () => (window.location.pathname = "contact"),
  },
];

export const CMDKBar = () => {
  const { results } = useMatches();
  const [queryText, setQueryText] = useState<string>();
  const setSearchedEvent = useEventsStore((state) => state.setSearchedEvent);
  const setActiveTab = useEventsStore((state) => state.setActiveTab);

  const debouncedQueryText = useDebounce<string>(queryText || "", 300);

  const searchResults = api.events.searchEvents.useQuery(
    { queryText: debouncedQueryText ?? "" },
    { enabled: !!debouncedQueryText && debouncedQueryText.length > 2 }
  );

  useEffect(() => {
    if (debouncedQueryText && debouncedQueryText.length > 2) {
      posthog.capture("Launchpad Searched", { query: debouncedQueryText });
    }
  }, [debouncedQueryText]);

  const eventActions = useMemo(() => {
    // .filter(
    //   (e) =>
    //     !(
    //       e.value.event_type === "prematch" &&
    //       parseInt(e.value.starts) < timeNow
    //     )
    // )
    const timeNow = new Date().getTime();
    return (
      searchResults.data?.documents &&
      [...searchResults.data?.documents]
        ?.filter(
          (event) =>
            !(
              event.value.event_type === "prematch" &&
              parseInt(event.value.starts) < timeNow
            ) &&
            /**
             * This is because we have started receiving alternate line events from our RapidAPI provider that are junk.
             * Really should filter them out in DOP, but this is a quick fix for now.
             */
            !event.value.league_name.toLowerCase().includes("alternate line")
        )
        ?.map((event) => {
          return {
            id: event.id,
            name: `${event.value.home} vs ${event.value.away} [${
              event.value.event_type === "prematch" ? "Prematch" : "In-play"
            }]`,
            subtitle: `${event.value.league_name} [${format(
              new Date(event.value.starts),
              "HH:mm dd/MM"
            )}]`,
            // shortcut: ["c"],
            // keywords: "email",
            perform: () => {
              setActiveTab("searchResults");
              setSearchedEvent(event.value);
            },
          };
        })
    );
  }, [searchResults.data?.documents, setActiveTab, setSearchedEvent]);

  useRegisterActions(eventActions ?? [], [searchResults]);

  return (
    <div className="w-[32rem] rounded-md border-2 border-neutral-700 bg-neutral-900 p-4 font-mono shadow-lg">
      <KBarSearch
        className="w-full border-0 border-b-2 border-neutral-700 bg-neutral-900 pb-2 text-sm text-amber-500 outline-none placeholder:text-neutral-400 "
        autoFocus
        onChange={(e) => setQueryText(e.target.value)}
        value={queryText}
        defaultPlaceholder="Search for an event"
      />
      <div className="pt-4">
        <KBarResults
          items={results}
          onRender={({ item, active }) =>
            typeof item === "string" ? (
              <div>{item}</div>
            ) : (
              <div
                className={`${
                  active
                    ? "bg-neutral-600 text-green-400"
                    : "bg-neutral-900 text-neutral-300"
                } rounded-md p-2 font-mono text-xs`}
              >
                <span className="font-semibold">{item.name}</span>
                <span
                  className={cn(
                    "block text-xs text-neutral-400",
                    active && "text-neutral-300"
                  )}
                >
                  {item.subtitle}
                </span>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};
