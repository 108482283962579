import { useEffect } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import { type LineType } from "../../types/redis/alerts";
import { calculateNoVigPrices } from "../../utils/calculateNoVigPrices";
import { formatOddsHandleUndefined } from "../../utils/formatOdds";
import { useEventsStore } from "../../store/store";
import firebaseApp from "../../firebaseInit";

const useFCM = () => {
  const fcmToken = useEventsStore((state) => state.fcmToken);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      if (!firebaseApp) {
        console.log("🔥 Firebase App is not initialized");
        return;
      }
      const messaging = getMessaging(firebaseApp);

      const unsubscribe = onMessage(messaging, (payload) => {
        console.log(
          "[firebase-messaging-sw.js] Received foreground message ",
          payload
        );
        /**
         * Calculate no vig price here
         */
        const alertData = (
          payload?.data?.alertData
            ? JSON.parse(payload?.data?.alertData)
            : undefined
        ) as
          | {
              type: "oddsDrop" | "openingLine" | "limitChange";
              lineType: LineType;
              outcome: string;
              priceHome?: string;
              priceAway?: string;
              priceDraw?: string;
              priceOver?: string;
              priceUnder?: string;
              percentage: string;
            }
          | undefined;

        let noVigPrices: {
          power: {
            home?: number | undefined;
            draw?: number | undefined;
            away?: number | undefined;
            over?: number | undefined;
            under?: number | undefined;
          };
          additive: {
            home?: number | undefined;
            draw?: number | undefined;
            away?: number | undefined;
            over?: number | undefined;
            under?: number | undefined;
          };
          multiplicative: {
            home?: number | undefined;
            draw?: number | undefined;
            away?: number | undefined;
            over?: number | undefined;
            under?: number | undefined;
          };
          shin: {
            home?: number | undefined;
            draw?: number | undefined;
            away?: number | undefined;
            over?: number | undefined;
            under?: number | undefined;
          };
        };

        let noVigPricesString = "";

        if (alertData) {
          if (alertData.type === "oddsDrop") {
            if (alertData.lineType === "money_line") {
              noVigPrices = calculateNoVigPrices({
                home: alertData.priceHome
                  ? parseFloat(alertData.priceHome)
                  : undefined,
                draw: alertData.priceDraw
                  ? parseFloat(alertData.priceDraw)
                  : undefined,
                away: alertData.priceAway
                  ? parseFloat(alertData.priceAway)
                  : undefined,
              });
              const outcomeKey = alertData.outcome;
              noVigPricesString =
                `NVP ${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "decimal"
                  ) ?? ""
                } (${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "american"
                  ) ?? ""
                })` +
                " " +
                `🔻 ${alertData.percentage}`;
            }

            if (alertData.lineType === "spread") {
              noVigPrices = calculateNoVigPrices({
                home: alertData.priceHome
                  ? parseFloat(alertData.priceHome)
                  : undefined,
                away: alertData.priceAway
                  ? parseFloat(alertData.priceAway)
                  : undefined,
              });
              const outcomeKey = alertData.outcome;
              noVigPricesString =
                `NVP ${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "decimal"
                  ) ?? ""
                } (${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "american"
                  ) ?? ""
                })` +
                " " +
                `🔻 ${alertData.percentage}`;
            }

            if (alertData.lineType === "total") {
              noVigPrices = calculateNoVigPrices({
                over: alertData.priceOver
                  ? parseFloat(alertData.priceOver)
                  : undefined,
                under: alertData.priceUnder
                  ? parseFloat(alertData.priceUnder)
                  : undefined,
              });
              const outcomeKey = alertData.outcome;
              noVigPricesString =
                `NVP ${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "decimal"
                  ) ?? ""
                } (${
                  formatOddsHandleUndefined(
                    noVigPrices.power[
                      outcomeKey as keyof typeof noVigPrices.power
                    ],
                    "american"
                  ) ?? ""
                })` +
                " " +
                `🔻 ${alertData.percentage}`;
            }
          }
        }

        // Customize notification here
        const notificationTitle =
          payload.data?.title ?? "ERROR: NO TITLE GIVEN";
        const notificationOptions = {
          body: `${payload.data?.body ?? "empty body"}\n${noVigPricesString}`,
          icon: "/icons/pod-192-icon.png",
          badge: "/icons/pod-192-icon.png",
          data: {
            url: "https://www.pinnacleoddsdropper.com/terminal",
          },
        };

        new Notification(notificationTitle, notificationOptions);
      });
      return () => unsubscribe();
    }
  }, [fcmToken]);
};

export default useFCM;
