import { type FirebaseApp, initializeApp } from "firebase/app";
import { env } from "./env.mjs";
import { isSupported } from "firebase/messaging";

const firebaseConfig = {
  apiKey: env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.NEXT_PUBLIC_FIREBASE_APP_ID,
};

const areRequiredBrowserAPIsSupported = await isSupported();

let firebaseApp: FirebaseApp | null = null;

if (areRequiredBrowserAPIsSupported) {
  console.log("🟢 Firebase APIs are supported. Initializing Firebase App...");
  firebaseApp = initializeApp(firebaseConfig);
}

export default firebaseApp;
