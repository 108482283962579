import { getToken, isSupported, type Messaging } from "firebase/messaging";
import { env } from "../../env.mjs";

export const getFCMToken = async (
  firebaseMessaging: Messaging,
  notificationPermission: "granted" | "default" | "denied" | null
) => {
  if (!firebaseMessaging) {
    console.log("🔥 Firebase Messaging is not initialized");
    return null;
  }

  if (typeof window !== "undefined" && "serviceWorker" in navigator) {
    console.log("🔥 firebase Service Worker is supported", {
      notificationPermission,
    });
    if (notificationPermission === "granted") {
      try {
        const isFCMSupported = await isSupported();
        if (!isFCMSupported) {
          console.error("firebase FCM is not supported on this browser");
          return null;
        }
        const serviceWorkerRegistration = await navigator.serviceWorker?.ready;
        console.log("Service Worker registration:", serviceWorkerRegistration);
        const token = await getToken(firebaseMessaging, {
          vapidKey: env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration,
        });
        console.log("firebase FCM Token:", token);
        return token;
      } catch (error) {
        console.error("firebase Error getting FCM token:", error);
        return null;
      }
    }
  } else {
    console.log("🔥 firebase Service Worker is not supported");
  }
  return null;
};
